.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
/*! elementor - v3.19.0 - 07-02-2024 */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}

/*! elementor - v3.19.0 - 07-02-2024 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
  .elementor-drop-cap {
  background-color: #69727d;
  color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
  .elementor-drop-cap {
  color: #69727d;
  border: 3px solid;
  background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap {
  margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap-letter {
  width: 1em;
  height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
  float: left;
  text-align: center;
  line-height: 1;
  font-size: 50px;
}
.elementor-widget-text-editor .elementor-drop-cap-letter {
  display: inline-block;
}

.m-box {
  border: 2px solid rgba(227, 232, 252, 0.2) !important;
  border-radius: 6px;
  padding: 20px 30px;
  background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    ),
    radial-gradient(
      158.48% 294.91% at 50% 320.96%,
      rgba(6, 214, 160, 0.2) 0,
      rgba(6, 214, 160, 0) 100%
    );
  backdrop-filter: blur(75px);
}

.m-card {
  background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0.1),
      hsla(0, 0%, 100%, 0.1)
    ),
    radial-gradient(
      158.48% 294.91% at 50% 320.96%,
      rgba(6, 214, 160, 0.2) 0,
      rgba(6, 214, 160, 0) 100%
    );
  backdrop-filter: blur(75px);
  padding: clamp(12px, 1.3vw, 17px);
  border: 1px solid rgba(227, 232, 252, 0.2);
  border-radius: 10px;
}
.m-card span {
  font-weight: 600;
}
.m-card h3 {
  margin: 0px;
  color: #00f88a;
}

.m-select {
  background-color: #00f88a;
  color: #070a15;
  box-shadow: 0px 0px 10px 0px #00a55c inset;
  border: 1px solid #00a55c;
  border-radius: 12px;
}

input[type="range"] {
  border: none !important;
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px !important;
  padding: 0px !important;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 25px;
  background: #ccc;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 25px;
  background: #ccc;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #00a55c;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #00f88a;
  border-radius: 16px;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 25px;
  width: 25px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #00f88a;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #00a55c;
  border-radius: 16px;
}
